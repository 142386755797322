import axios from 'axios';

// create an axios instance
const service = axios.create({
    // baseURL: 'http://localhost:8000', // api base_url
    // baseURL: '/', // api base_url
    baseURL: 'https://stat.maktab.uz',
    timeout: 45000 // request timeout
})


// request interceptor
service.interceptors.request.use(config => {
    return config
}, error => {

   return  Promise.reject(error)
})

// respone interceptor
service.interceptors.response.use(
    response => response,
    error => {
        return Promise.reject(error)
    })

export default service
